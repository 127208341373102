<template>
  <v-expansion-panel 
    @click="handleExpand"
  >
    <v-expansion-panel-header>{{ $t('dtouch.workspace.dviewcontentMessages.title', locale) }}</v-expansion-panel-header>
    <v-expansion-panel-content style="padding-top:16px;">
      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
          <v-autocomplete
            v-model="ID"
            :items="workspaces"
            :loading="loading"
            outlined
            dense
            item-text="name"
            item-value="id"
            :label="$t('dtouch.workspace.dviewcontentMessages.id', locale)"
            hide-details
            @change="handleChange"
          />
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import api from '@/services/api'
export default {
  name: 'dviewcontentMessagesForm',
  props: {
    workspaceID: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    onChangeData: {
      type: Function,
      default: null,
    },
  },
  data: () => ({
    ID: null,
    workspaces: [],
    loading: true
  }),
  methods: {
    getWorkspaces () {
      api.getAllWithoutLimit('dtouch', `v1/system/dviewcontent/`)
        .then(response => {
          this.workspaces = response.data.map(item => {
            return ({
              id: item.ID,
              name: item.Name
            })
          })
          this.loading = false
      })
    },
    getData () {
      api.getItem('dtouch', `v1/system/workspaces/`, `${this.workspaceID}/dviewcontent-messages`)
        .then(response => {
          this.ID = response ? response.ID : null
        })
    },
    handleExpand () {
      this.getWorkspaces()
      if(!this.ID && this.workspaceID) this.getData()
    },
    handleChange () {
      this.onChangeData({
        ID: this.ID,
      })
    }
  },
}
</script>


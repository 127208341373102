<template>
  <v-expansion-panel 
    @click="handleExpand"
  >
    <v-expansion-panel-header>{{ $t('dtouch.workspace.dial.title', locale) }}</v-expansion-panel-header>
    <v-expansion-panel-content style="padding-top:16px;">
      <v-layout wrap>
        <v-flex xs6>
          <v-checkbox
            v-model="isCustomDial"
            :label="$t('dtouch.workspace.dial.isCustomDial', locale)"
            hide-details
            @change="handleChange"
          />
        </v-flex>
        <v-flex xs6 v-if="isCustomDial">
          <v-text-field
            outlined
            v-model="floatingDialCustom"
            :label="$t('dtouch.workspace.dial.floatingDialCustom', locale)"
            hide-details
            @change="handleChange"
          />
        </v-flex>

        <template v-if="!isCustomDial">
          <v-flex xs12>
            <v-layout wrap style="margin: 10px 0;">
              <v-flex xs3>
                <v-checkbox
                  v-model="whatsApp"
                  :label="$t('dtouch.workspace.dial.whatsApp', locale)"
                  hide-details
                  @change="handleChange"
                />
              </v-flex>
              <v-flex xs3 v-if="whatsApp">
                <v-checkbox
                  v-model="whatsAppAuth"
                  :label="$t('dtouch.workspace.dial.whatsAppAuth', locale)"
                  hide-details
                  @change="handleChange"
                />
              </v-flex>
              <v-flex xs6 v-if="whatsApp">
                <v-text-field
                  outlined
                  v-model="whatsAppPhone"
                  :label="$t('dtouch.workspace.dial.whatsAppPhone', locale)"
                  hide-details
                  @change="handleChange"
                />
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12>
            <v-layout wrap style="padding-bottom:10px">
              <v-flex xs6>
                <v-checkbox
                  v-model="floatingDial"
                  :label="$t('dtouch.workspace.dial.floatingDial', locale)"
                  hide-details
                  @change="handleChange"
                />
              </v-flex>
              <v-flex xs6 v-if="floatingDial"> 
                <color-picker-item
                  :value="dialColor"
                  label="Dial color"
                  :onChange="handleChangeDialColor"
                  borderRadius="20px 0 20px 0"
                />
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 v-if="floatingDial" style="padding-bottom:10px">
            <v-text-field
              outlined
              hide-details
              v-model="instagramURL"
              label="URL Instagram"
              @change="handleChange"
            />
          </v-flex>
          <v-flex xs12 v-if="floatingDial" style="padding-bottom:10px">
            <v-text-field
              outlined
              hide-details
              v-model="youtubeURL"
              label="URL Youtube"
              @change="handleChange"
            />
          </v-flex>
          <v-flex xs12 v-if="floatingDial" style="padding-bottom:10px">
            <v-text-field
              outlined
              hide-details
              v-model="twitterURL"
              label="URL Twitter"
              @change="handleChange"
            />
          </v-flex>
          <v-flex xs12 v-if="floatingDial" style="padding-bottom:10px">
            <v-text-field
              outlined
              hide-details
              v-model="facebookURL"
              label="URL Facebook"
              @change="handleChange"
            />
          </v-flex>
          <v-flex xs12 v-if="floatingDial" >
            <v-layout wrap>
              <v-flex xs12>
                <small>Custom: </small>
              </v-flex>
          <v-flex xs12 style="padding-bottom:10px">
            <color-picker-item
              :value="dial1.color"
              label="Color"
              :onChange="handleChangeCustomDialColor"
              borderRadius="20px 0 20px 0"
            />
          </v-flex>
          <v-flex xs12 style="padding-bottom:10px">
            <v-text-field
              outlined
              v-model="dial1.icon"
              label="Icon"
              hide-details
              @change="handleChange"
            />
          </v-flex>
          <v-flex xs12 style="padding-bottom:10px">
            <v-text-field
              outlined
              v-model="dial1.label"
              label="Label"
              hide-details
              @change="handleChange"
            />
          </v-flex>
          <v-flex xs12>
            <v-text-field
              outlined
              v-model="dial1.URL"
              label="URL"
              hide-details
              @change="handleChange"
            />
          </v-flex>
            </v-layout>
          </v-flex>
        </template>
      </v-layout>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import api from '@/services/api'
import ColorPickerItem from '@/components/common-components/ColorPickerItem'
export default {
  name: 'DialForm',
  components: {
    ColorPickerItem,
  },
  props: {
    workspaceID: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    onChangeData: {
      type: Function,
      default: null,
    },
  },
  data: () => ({
    loading: true,
    loaded: false,
    isCustomDial: false,
    floatingDialCustom: null,
    
    whatsApp: false,
    whatsAppAuth: false,
    whatsAppPhone: null,
    floatingDial: false,
    dialColor: null,

    instagramURL: null,
    youtubeURL: null,
    twitterURL: null,
    facebookURL: null,
    dial1: {}
  }),
  methods: {
    getData () {
      api.getItem('dtouch', `v1/system/workspaces/`, `${this.workspaceID}/dial`)
        .then(response => {
          this.loaded = true
          if (response) {
            this.isCustomDial = response.FloatingDialCustom && response.FloatingDialCustom.length > 0
            this.floatingDialCustom = response.FloatingDialCustom

            this.whatsApp = response.WhatsApp ? true : false
            this.whatsAppAuth = response.WhatsAppAuth ? true : false
            this.whatsAppPhone = response.WhatsAppPhone

            this.floatingDial = response.FloatingDial ? true : false
    
            this.dialColor = response.DialColor

            this.instagramURL = response.InstagramURL
            this.youtubeURL = response.YoutubeURL
            this.twitterURL = response.TwitterURL
            this.facebookURL = response.FacebookURL
            this.dial1 = response.Dial1 && response.Dial1.length > 0 ? JSON.parse(response.Dial1) : {}
          }
        })
    },
    handleExpand () {
      if(!this.loaded && this.workspaceID) this.getData()
    },
    handleChange () {
      this.onChangeData({
        FloatingDialCustom: this.isCustomDial ? this.floatingDialCustom : null,

        WhatsApp: this.whatsApp,
        WhatsAppAuth: this.whatsAppAuth,
        WhatsAppPhone: this.whatsAppPhone,

        FloatingDial : this.floatingDial,
        DialColor: this.dialColor,
        InstagramURL: this.instagramURL,
        YoutubeURL: this.youtubeURL,
        TwitterURL: this.twitterURL,
        FacebookURL: this.facebookURL,
        Dial1: this.dial1,
      })
    },
    handleChangeDialColor (v) {
      this.dialColor = v
      this.handleChange()
    },
    handleChangeCustomDialColor (v) {
      this.dial1.color = v
      this.handleChange()
    },
  },
}
</script>


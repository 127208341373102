<template>
  <v-expansion-panel 
    @click="handleExpand"
  >
    <v-expansion-panel-header>{{ $t('dtouch.workspace.customURLBack.title', locale) }}</v-expansion-panel-header>
    <v-expansion-panel-content style="padding-top:16px;">
      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
          <v-text-field
            outlined
            v-model="customURLBack"
            label="URL"
            hide-details
            @change="handleChange"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
          <center><b>NOTA:</b> para que aparezca la url debe incorporar <b>"?back"</b><br>https://{{domain}}.dtouch.es/#/?bc=true</center>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import api from '@/services/api'
export default {
  name: 'CustomURLBackForm',
  props: {
    workspaceID: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    onChangeData: {
      type: Function,
      default: null,
    },
  },
  data: () => ({
    loading: true,
    customURLBack: null,
    domain: '',
  }),
  methods: {
    getData () {
      api.getItem('dtouch', `v1/system/workspaces/`, `${this.workspaceID}/custom-url-back`)
        .then(response => {
          this.domain = response ? response.Domain : ''
          this.customURLBack = response ? response.CustomURLBack : null
        })
    },
    handleExpand () {
      if(!this.hotelequia && this.workspaceID) this.getData()
    },
    handleChange () {
      this.onChangeData({
        CustomURLBack: this.customURLBack,
      })
    }
  },
}
</script>


<template>
  <v-expansion-panel 
    @click="handleExpand"
  >
    <v-expansion-panel-header>Deepl translate</v-expansion-panel-header>
    <v-expansion-panel-content style="padding-top:16px;">
      <v-layout wrap v-if="editedItem">
        <v-flex xs12>
          <v-checkbox 
            label="Activo" 
            v-model="editedItem.DtouchDeeplTranslateActive"
            @change="handleChange"
          />
        </v-flex>
        <v-flex xs12 style="padding-bottom: 20px">
          <v-text-field
            outlined
            v-model="editedItem.DtouchDeeplTranslateLocale"
            label="Locale"
            hide-details
            @change="handleChange"
          />
        </v-flex>
        <v-flex xs12>
          <v-text-field
            outlined
            v-model="editedItem.DtouchDeeplToken"
            label="Deepl token"
            hide-details
            @change="handleChange"
          />
        </v-flex>
      </v-layout>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import api from '@/services/api'
export default {
  name: 'DeeplTranslateForm',
  components: {
  },
  props: {
    workspaceID: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    onChangeData: {
      type: Function,
      default: null,
    },
  },
  data: () => ({
    loading: true,
    editedItem: null,
  }),
  methods: {
    getData () {
      api.getItem('dtouch', `v1/system/workspaces/`, `${this.workspaceID}/deepl-translate`)
        .then(response => {
          this.editedItem = response
          console.log(response)
        })
    },
    handleExpand () {
      if(!this.editedItem && this.workspaceID) this.getData()
    },
    handleChange () {
      this.onChangeData(this.editedItem)
    },
  },
}
</script>


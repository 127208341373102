<template>
  <v-expansion-panel 
    @click="handleExpand"
  >
    <v-expansion-panel-header>
      {{ $t('dtouch.workspace.totem.title', locale) }}
    </v-expansion-panel-header>
    <v-expansion-panel-content style="padding-top:16px;">
      <v-row v-if="totemData">
        <v-col
          cols="12"
          sm="12"
          style="padding: 20px 11px 25px 11px;"
        >
          <totem-preview 
            v-if="domain"
            :domain="domain"
            :locale="locale"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          style="padding:20px; background-color: #CCCCCC80;"
        >
        <small>Men� type : </small>
          <v-radio-group 
            v-model="selectedMainMenuType"
            @change="handleChangeData"
          >
            <v-layout 
              wrap
              v-for="item of mainMenuTypes"
              :key="item.id"
              style="margin-top:5px;"
            >
              <v-flex 
                xs1
              >
                <v-radio
                  :value="item.id"
                />
              </v-flex>
              <v-flex 
                xs11
              >
                <v-img :src="item.img" />
              </v-flex>
            </v-layout>
          </v-radio-group>

          <v-checkbox 
            label="No shadow"
            v-model="totemNoShadow"
            @change="handleChangeData"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          style="padding:20px; background-color: #FCF3CF;"
        >
        <small style="right: 0px;text-align:right;font-style:italic;">* solo para <b>?fullScreen=true</b></small>
          <br />
          <small>
            Header Parrilla :
          </small>
          <v-text-field
            hide-details
            outlined
            v-model="totemParrilla"
            style="margin-top: 10px;"
            @change="handleChangeData"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          style="padding: 0 20px; background-color: #FCF3CF;"
        >
          <small>
            Header logo :
          </small>
          <select-image 
            style="margin-top:20px"
            :image="totemLogo"
            :onChange="handleChangeImage"
            :locale="locale"
            :imageWidth="360"
            :imageHeight="73"
            :folder="`${workspaceID}/totem`"
          />
            <br />
            <small style="font-style:italic;">NOTA: <b>363x73px</b></small>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          style="padding:20px; background-color: #FCF3CF;"
        >
          <small>
            Mensaje / aviso :
          </small>
          <v-text-field
            hide-details
            outlined
            v-model="totemMessage"
            style="margin-top: 10px;"
            @change="handleChangeData"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          style="margin-top: 20px;border-radius: 5px; padding: 20px 20px 0 20px;background-color:#FF573380"
        >
          <v-text-field
            hide-details
            outlined
            label="URL QR (https://demo.demo.es)"
            v-model="customURL"
            style="margin-top: 10px;"
            @change="handleChangeData"
          />
          <div style="padding: 10px;">
          <translate
            v-model="customURLInfo"
            :languages="languages"
            label="QR Nota"
            @input="handleChangeData"
            type="text"
          />
          </div>
        </v-col>

        <v-col
          cols="12"
          sm="12"
          style="padding:20px"
        >
          <small>
            Background main :
          </small>
          <v-text-field
            hide-details
            outlined
            v-model="totemBackground"
            style="margin-top: 10px;"
            @change="handleChangeData"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          style="padding: 0 20px 20px 20px"
        >
          <small>
            XML pantalla completa :
          </small>
          <v-text-field
            hide-details
            outlined
            v-model="totemScreenSaver"
            style="margin-top: 10px;"
            @change="handleChangeData"
          />
            <small style="font-style:italic;">NOTA: <b>se activa tras x min. de inactividad</b></small>
        </v-col>
        <v-col
          v-for="(item, index) of totemData"
          :key="index"
          cols="12"
          sm="12"
          style="background-color: #CCCCCC80;margin-bottom: 5px; border-radius:5px;"
        >
          <small>
            Men� {{index + 1}} :
          </small>
          <ItemTotem
            :index="index"
            :workspaceID="workspaceID"
            :dtouchWorkspaces="dtouchWorkspaces"
            :interactiveMaps="interactiveMaps"
            :editedItem="item"
            :locale="locale"
            :onChangeData="handleChangeItem"
          />
          <small v-if="index >= 4 " style="color: red;">
            <b>
             NOTA: Solo para totem horizontal !!!
            </b>
          </small>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          style="padding:10px 0;"
        >
          <v-btn
            @click="handleForceRefresh"
            block
            color="red"
            dark
          >
            Force refresh [ {{totemLMD ? totemLMD.split('T').join(' ').split('.').shift() : ''}} ]
          </v-btn>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import axios from 'axios'
import api from '@/services/api'
import apiDtouch from '@/services/apiDtouch'
import Translate from '@/components/Translate'
import SelectImage from '@/components/selectImage/Index'
import ItemTotem from './Item'
import TotemPreview from './TotemPreview'
import menuTypeRight from '@/assets/totemMainMenuTypes/totemMainMenuDefault.png'
import menuTypeBetween from '@/assets/totemMainMenuTypes/totemMainMenuBetween.png'
import menuTypeLogo from '@/assets/totemMainMenuTypes/totemMainMenuLogo.png'
export default {
  name: 'DfileForm',
  components: {
    Translate,
    ItemTotem,
    SelectImage,
    TotemPreview,
  },
  props: {
    workspaceID: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    onChangeData: {
      type: Function,
      default: null,
    },
  },
  data: () => ({
    languages: [ 'es', 'en', 'de' ],
    domain: null,
    totemData: null,
    dtouchWorkspaces: [],
    interactiveMaps: [],
    loading: true,
    totemLogo: null,
    newImage: null,
    totemLMD: null,
    totemMessage: null,
    totemParrilla: null,
    totemBackground: null,
    totemScreenSaver: null,
    totemNoShadow: null,
    customURL: null,
    customURLInfo: null,
    selectedMainMenuType: 'right',
    types: [
      {
        id: null,
        name: '',
      },
      {
        id: 'domain',
        name: 'webapp',
      },
      {
        id: 'meteo',
        name: 'meteo',
      },
      {
        id: 'activity',
        name: 'actividades',
      },
      {
        id: 'externalLink',
        name: 'externalLink',
      },
    ],
  }),
  computed: {
    mainMenuTypes () {
      return [
        {
          id: 'right',
          img: menuTypeRight,
        }, {
          id: 'between',
          img: menuTypeBetween,
        }, {
          id: 'logo',
          img: menuTypeLogo,
        }
      ]
    },
  },
  methods: {
    handleExpand () {
      this.getInteractiveMaps()
      this.getWorkspaces()
    },
    getInteractiveMaps () {
      apiDtouch.getAll (`v2/private/workspaces/${this.workspaceID}/interactive-maps`)
        .then(response => {
          this.interactiveMaps = response.map(item => {
            return {
              ID: item.ID,
              Name: item.Name,
            }
          })
        })
    },
    getWorkspaces () {
      api.getAllWithoutLimit('dtouch', 'v1/system/workspaces')
        .then(response => {
          this.dtouchWorkspaces = response.data.map(item => {
            return ({
              id: item.ID,
              name: item.Name,
              domain: item.Domain,
            })
          })
          this.loading = false
          if(!this.totemData && this.workspaceID) this.getTotemData()
      })
    },
    getTotemData () {
      api.getItem('dtouch', `v1/public/workspaces/`, `${this.workspaceID}/totem`)
        .then(response => {
          this.customURL = response && response.CustomURL ? response.CustomURL : null
          if (response && response.Config) {
            this.domain = response.Domain
            this.totemLogo = response.TotemLogo
            this.totemLMD = response.TotemLMD
            this.totemBackground = response.TotemBackground
            this.totemParrilla = response.TotemParrilla
            this.totemMessage = response.TotemMessage
            this.totemScreenSaver = response.TotemScreenSaver
            this.totemNoShadow = response.TotemNoShadow ? true : false
            this.selectedMainMenuType = response.TotemMainMenuType ? response.TotemMainMenuType : 'right'
            this.customURLInfo = response.TotemCustomURLInfo ? JSON.parse(response.TotemCustomURLInfo) : null
            this.prepareTotemData(JSON.parse(response.Config))
          } else this.prepareTotemData()
        })
    },
    prepareTotemData (v) {
      if (!v) {
        const aux = this.dtouchWorkspaces.filter(x => x.id === this.workspaceID).shift()
        this.totemData = [
          {
            name: { es: aux.name },
            type: 'domain',
            action: `@${aux.domain}`,
            domain: aux.id,
            image: 'https://www.dtouch.es/img/logodtouch.png',
          },
          {
            name: { es: '' },
            action: null,
            image: 'https://www.dtouch.es/img/logodtouch.png',
          },
          {
            name: { es: '' },
            action: null,
            image: 'https://www.dtouch.es/img/logodtouch.png',
          },
          {
            name: { es: '' },
            action: null,
            image: 'https://www.dtouch.es/img/logodtouch.png',
          },
          {
            name: { es: '' },
            action: null,
            image: 'https://www.dtouch.es/img/logodtouch.png',
          },
          {
            name: { es: '' },
            action: null,
            image: 'https://www.dtouch.es/img/logodtouch.png',
          },
        ]
      } else {
        if (v.length === 4) {
          v.push({
            name: { es: '' },
            action: null,
            image: 'https://www.dtouch.es/img/logodtouch.png',
          })
          v.push({
            name: { es: '' },
            action: null,
            image: 'https://www.dtouch.es/img/logodtouch.png',
          })
        }
        this.totemData = v
      }
    },
    handleChangeImage (v) {
      if (!v) return
      this.totemLogo = v.base64
      this.newImage = v //'https://social.infoten.es/Fichas/PublishingImages/capture_03112013_152110.jpg'
      this.handleChangeData()
    },
    handleChangeItem (index, v) {
      this.totemData[index] = JSON.parse(JSON.stringify(v))
      this.handleChangeData()
    },
    handleChangeData () {
      /*
      this.totemData = this.totemData.map(item => {
        const aux = this.dtouchWorkspaces.filter(x => x.id === item.domain).shift()
        item.action = item.type === 'domain' && aux ? `@${aux.domain}`
                      : item.type === 'meteo' ? ':meteo'
                      : item.type === 'activity' ? ':activity' : null
        return item
      })*/
      this.onChangeData({
        totemData: {
          totemMessage: this.totemMessage,
          totemParrilla: this.totemParrilla,
          totemBackground: this.totemBackground,
          totemScreenSaver: this.totemScreenSaver,
          totemNoShadow: this.totemNoShadow,
          menus: this.totemData,
          customURL: this.customURL,
          totemCustomURLInfo: this.customURLInfo,
          newImage: this.newImage,
          totemLogo: this.totemLogo,
          totemMainMenuType: this.selectedMainMenuType,
          totemLMD: this.totemLMD
        }
      })
    },
    handleForceRefresh () {
      //const url = `${process.env.VUE_APP_ENV === 'development' ? 'http://localhost:3803/' : 'https://cm-dtouch-api.dtouch.es/'}v1/public/system/totemLMD/${this.workspaceID}`
      const url = `${process.env.VUE_APP_ENV === 'development' ? 'http://localhost:3803/' : 'https://cm-dtouch-api.dtouch.es/'}v1/system/workspaces/totemLMD/${this.workspaceID}`
      axios.patch(url)
        .then (response => {
          console.log(response)
        })
    },
  },
}
</script>


<template>
  <v-row v-if="loading">
    <v-col
      cols="12"
      sm="12"
    >
      loading
    </v-col>
  </v-row>
  <v-row v-else-if="editedItem">
    <v-col
      cols="12"
      sm="12"
    >
      <v-text-field
        v-model="editedItem.TokenURL"
        label="TokenURL"
        hide-details
        @change="handleChange"
      />
    </v-col>
    <v-col
      cols="12"
      sm="12"
    >
      <v-text-field
        v-model="editedItem.GrantType"
        label="GrantType"
        hide-details
        @change="handleChange"
      />
    </v-col>
    <v-col
      cols="12"
      sm="12"
    >
      <v-text-field
        v-model="editedItem.ClientID"
        label="ClientID"
        hide-details
        @change="handleChange"
      />
    </v-col>
    <v-col
      cols="12"
      sm="12"
    >
      <v-text-field
        v-model="editedItem.ClientSecret"
        label="ClientSecret"
        hide-details
        @change="handleChange"
      />
    </v-col>
    <v-col
      cols="12"
      sm="12"
    >
      <v-text-field
        v-model="editedItem.Scope"
        label="Scope"
        hide-details
        @change="handleChange"
      />
    </v-col>
    <v-col
      cols="12"
      sm="12"
    >
      <v-text-field
        v-model="editedItem.ApiURL"
        label="ApiURL"
        hide-details
        @change="handleChange"
      />
    </v-col>
    <v-col
      v-if="companies"
      cols="12"
      sm="12"
    >
      <v-autocomplete
        v-model="editedItem.CompanyID"
        :items="companies"
        outlined
        chips
        small-chips
        item-text="Name"
        item-value="ID"
        label="CompanyID"
        hide-details
        @change="handleChange"
      />
    </v-col>
    <v-col
      v-if="fields"
      cols="12"
      sm="12"
    >
      <v-autocomplete
        v-model="editedItem.Fields"
        :items="fields"
        outlined
        chips
        small-chips
        item-text="key"
        item-value="key"
        label="Fields"
        multiple
        hide-details
        @change="handleChange"
      />
    </v-col>
  </v-row>
</template>
<script>
import api from '@/services/api'
export default {
  props: {
    workspaceID: {
      type: String,
      required: true,
    },
    onChange: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    editedItem: null,
    companies: null,
    fields: null,
  }),
  watch: {
    workspaceID () {
      this.handleGetData()
    },
  },
  mounted () {
    this.handleGetFields()
    this.handleGetData()
    this.handleGetBookings()
  },
  methods: {
    handleChange () {
      this.handleGetCompanies()
      this.onChange(JSON.parse(JSON.stringify(this.editedItem)))
    },
    handleGetFields () {
      api.getAll('dtouch', `v1/system/noray/fields/`)
        .then(response => {
          this.fields = response
        })
        .catch((error) => {
          console.log(error)
        })
    },
    handleGetCompanies () {
      if (!this.editedItem.TokenURL || this.editedItem.TokenURL.length === 0
        || !this.editedItem.GrantType || this.editedItem.GrantType.length === 0
        || !this.editedItem.ClientID || this.editedItem.ClientID.length === 0
        || !this.editedItem.ClientSecret || this.editedItem.ClientSecret.length === 0
        || !this.editedItem.Scope || this.editedItem.Scope.length === 0
        || !this.editedItem.ApiURL || this.editedItem.ApiURL.length === 0
        ) {
        this.companies = null
        return
      }
      api.put('dtouch', `v1/system/noray/companies/`, this.editedItem)
        .then(response => {
          this.companies = null
          if (!response || !response.data || !response.data.value) return
          this.companies = response.data.value.map(item => {
            return { ID: item.id, Name: item.name }
          })
        })
        .catch((error) => {
          console.log(error)
        })
    },
    handleGetBookings () {
      api.getAll('dtouch', `v1/system/workspaces/${this.workspaceID}/noray/bookings/`)
        .then(response => {
          console.log(response.shift())
        })
    },
    handleGetData () {
      this.loading = true
      this.editedItem = {}
      api.getItem('dtouch', `v1/system/workspaces/`, `${this.workspaceID}/noray/`)
        .then(response => {
          if (response) {
            this.editedItem = response
            this.handleGetCompanies()
          } else {
            this.editedItem = {
              /*TokenURL: null,
              GrantType: null,
              ClientID: null,
              ClientSecret: null,
              Scope: null,
              ApiURL: null,
              CompanyID: null,*/
              TokenURL: 'https://login.microsoftonline.com/8adc3ee6-b964-44ca-bb94-c939fd272c56/oauth2/v2.0/token',
              GrantType: 'client_credentials',
              ClientID: 'ce0ada5b-8908-4817-b7f8-a406faff044d',
              ClientSecret: 'mEd8Q~Yl_VRGF8G6edmJBowSOtSfYm4Q6eg6-b7S',
              Scope: 'https://api.businesscentral.dynamics.com/.default',
              ApiURL: 'https://api.businesscentral.dynamics.com/v2.0/8adc3ee6-b964-44ca-bb94-c939fd272c56/NorayHTLSandbox/api',
              CompanyID: null,
            }
            this.handleGetCompanies()
          }
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
        })
    },
  },
}
</script>


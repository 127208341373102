<template>
  <v-expansion-panel 
    @click="handleExpand"
  >
    <v-expansion-panel-header>{{ $t('dtouch.workspace.welcomeForm.title', locale) }}</v-expansion-panel-header>
    <v-expansion-panel-content style="padding-top:16px;">
      <v-row v-if="welcomeFormData">
        <v-col
          cols="12"
          sm="12"
          style="padding:20px; background-color: #CCCCCC80;"
        >
          <v-switch
            v-model="isWelcomeForm"
            :label="`Activo`"
            @change="handleChangeData"
          />
        </v-col>
        <v-col
          v-if="isWelcomeForm"
          cols="12"
          sm="12"
          style="padding:20px; background-color: #FCF3CF;"
        >
          <v-checkbox
            v-model="welcomeFormData.phone"
            :label="`Tel�fono`"
            @change="handleChangeData"
            hide-details
          />
          <v-checkbox
            v-model="welcomeFormData.email"
            :label="`Email`"
            @change="handleChangeData"
            hide-details
          />
          <v-checkbox
            v-model="welcomeFormData.name"
            :label="`Nombre`"
            @change="handleChangeData"
            hide-details
          />

          <translate
            v-model="welcomeFormData.terms"
            :languages="languages"
            label="T�rminos"
            @input="handleChangeData"
            type="textArea"
            style="margin-top:10px;"
          />
        </v-col>
        <!--
        <v-col
          cols="12"
          sm="12"
          style="padding:20px; background-color: #FCF3CF;"
        >
        <small style="right: 0px;text-align:right;font-style:italic;">* solo para <b>?fullScreen=true</b></small>
          <br />
          <small>
            Header Parrilla :
          </small>
          <v-text-field
            hide-details
            outlined
            v-model="totemParrilla"
            style="margin-top: 10px;"
            @change="handleChangeData"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          style="padding: 0 20px; background-color: #FCF3CF;"
        >
          <small>
            Header logo :
          </small>
          <select-image 
            style="margin-top:20px"
            :image="totemLogo"
            :onChange="handleChangeImage"
            :locale="locale"
            :imageWidth="360"
            :imageHeight="73"
            :folder="`${workspaceID}/totem`"
          />
            <br />
            <small style="font-style:italic;">NOTA: <b>363x73px</b></small>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          style="padding:20px; background-color: #FCF3CF;"
        >
          <small>
            Mensaje / aviso :
          </small>
          <v-text-field
            hide-details
            outlined
            v-model="totemMessage"
            style="margin-top: 10px;"
            @change="handleChangeData"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          style="margin-top: 20px;border-radius: 5px; padding: 20px 20px 0 20px;background-color:#FF573380"
        >
          <v-text-field
            hide-details
            outlined
            label="URL QR (https://demo.demo.es)"
            v-model="customURL"
            style="margin-top: 10px;"
            @change="handleChangeData"
          />
          <div style="padding: 10px;">
          </div>
        </v-col>

        <v-col
          cols="12"
          sm="12"
          style="padding:20px"
        >
          <small>
            Background main :
          </small>
          <v-text-field
            hide-details
            outlined
            v-model="totemBackground"
            style="margin-top: 10px;"
            @change="handleChangeData"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          style="padding: 0 20px 20px 20px"
        >
          <small>
            XML pantalla completa :
          </small>
          <v-text-field
            hide-details
            outlined
            v-model="totemScreenSaver"
            style="margin-top: 10px;"
            @change="handleChangeData"
          />
            <small style="font-style:italic;">NOTA: <b>se activa tras x min. de inactividad</b></small>
        </v-col>-->
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import api from '@/services/api'
import Translate from '@/components/Translate'
export default {
  name: 'WelcomeFormForm',
  components: {
    Translate,
  },
  props: {
    workspaceID: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    onChangeData: {
      type: Function,
      default: null,
    },
  },
  data: () => ({
    languages: [ 'es', 'en', 'de' ],
    isWelcomeForm: false,
    welcomeFormData: null,
  }),
  methods: {
    handleGetData () {
      api.getItem('dtouch', `v1/public/workspaces/`, `${this.workspaceID}/welcome-form`)
        .then(response => {
          if (!response) return 

          response.config.terms = response.config.terms ? response.config.terms : { es: '' }
          this.welcomeFormData = response.config
          this.isWelcomeForm = response.isWelcomeForm
          /*
          this.customURL = response && response.CustomURL ? response.CustomURL : null
          if (response && response.Config) {
            this.totemLogo = response.TotemLogo
            this.totemBackground = response.TotemBackground
            this.totemParrilla = response.TotemParrilla
            this.totemMessage = response.TotemMessage
            this.totemScreenSaver = response.TotemScreenSaver
            this.selectedMainMenuType = response.TotemMainMenuType ? response.TotemMainMenuType : 'right'
            this.customURLInfo = response.TotemCustomURLInfo ? JSON.parse(response.TotemCustomURLInfo) : null
            this.prepareTotemData(JSON.parse(response.Config))
          } else this.prepareTotemData()*/
        })
    },
    handleExpand () {
      this.handleGetData()
    },
    handleChangeData () {
      console.log(this.welcomeFormData)
      const { terms, email, phone, name } = this.welcomeFormData
      this.onChangeData({
        isWelcomeForm: this.isWelcomeForm,
        terms,
        email,
        phone,
        name,
      })
    }
  },
}
</script>


<template>
  <v-expansion-panel 
    @click="handleExpand"
  >
    <v-expansion-panel-header>{{ $t('dtouch.workspace.meteo.title', locale) }}</v-expansion-panel-header>
    <v-expansion-panel-content style="padding-top:16px;">
      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
          <v-autocomplete
            v-model="meteoID"
            :items="zones"
            :loading="loading"
            outlined
            dense
            item-text="name"
            item-value="zone"
            :label="$t('dtouch.workspace.meteo.zone', locale)"
            hide-details
            @change="handleChangeMeteoData"
          />
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import api from '@/services/api'
export default {
  name: 'MeteoForm',
  props: {
    workspaceID: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    onChangeData: {
      type: Function,
      default: null,
    },
  },
  data: () => ({
    meteoID: null,
    zones: [],
    loading: true
  }),
  methods: {
    getZones () {
      api.getAllWithoutLimit('dviewcontent', 'v1/internal/meteos/zones')
        .then(response => {            
          this.zones = response.map(item => {
            return ({
              id: item.ID,
              zone: item.NombreMun,
              name: item.Lugar
            })
          })
          this.loading = false
      })
    },
    getMeteoData () {
      api.getItem('dtouch', `v1/system/workspaces/`, `${this.workspaceID}/meteo`)
        .then(response => {
          if (response) {
            this.meteoID = response.MeteoID
          }
        })
    },
    handleExpand () {
      this.getZones()
      if(!this.meteoID && this.workspaceID) this.getMeteoData()
    },
    handleChangeMeteoData () {
      this.onChangeData({
        meteoID: this.meteoID,
      })
    }
  },
}
</script>


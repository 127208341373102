<template>
  <v-autocomplete
    v-model="selectedContentTypes"
    :items="contentTypes"
    filled
    chips
    color="blue-grey lighten-2"
    :label="$t('dtouch.workspace.contentTypes', locale)"
    item-text="name"
    item-value="id"
    multiple
    hide-details
    class="content-type-form"
  >
    <template v-slot:selection="data">
      <v-chip
        small
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        @click="data.select"
        @click:close="remove(data.item)"
      >
        <v-avatar left>
          <v-icon x-small>{{ data.item.icon }}</v-icon>
        </v-avatar>
        {{ $t(`contentType.${data.item.name}`, locale) }}
      </v-chip>
    </template>
    <template v-slot:item="data">
      <template v-if="typeof data.item !== 'object'">
        <v-list-item-content v-text="data.item"></v-list-item-content>
      </template>
      <template v-else>
        <v-list-item-avatar>
          <v-icon x-small>{{ data.item.icon }}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title v-html="$t(`contentType.${data.item.name}`, locale)"></v-list-item-title>
          <!--v-list-item-subtitle v-html="data.item.group"></v-list-item-subtitle-->
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>
<script>
import api from '@/services/api'
export default {
  props: {
    locale: {
      type: String,
      default: null,
    },
    permission: {
      type: Array,
      default: null,
    },
    onChange: {
      type: Function,
      required: true,
    },
  },
  data () {
    return {
      contentTypes: [],
      selectedContentTypes: [],
    }
  },
  watch: {
    selectedContentTypes (v) {
      this.onChange(v)
    },
  },
  mounted () {
    this.getContentTypes ()
    this.selectedContentTypes = this.permission
  },
  methods: {
    getContentTypes () {
      api.getAllWithoutLimit ('dtouch', 'v1/private/contents/content-types')
        .then((response) => {
          this.contentTypes = response
        })
    },
    remove (v) {
      const index = this.selectedContentTypes.indexOf(v.id)
      if (index >= 0) this.selectedContentTypes.splice(index, 1)
    },
  },
}
</script>
<style>
.content-type-form .v-select__selections {
  padding-top: 30px !important;
}
</style>


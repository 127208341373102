<template>
  <v-expansion-panel 
    @click="handleExpand"
  >
    <v-expansion-panel-header>{{ $t('dtouch.workspace.hotelequia.title', locale) }}</v-expansion-panel-header>
    <v-expansion-panel-content style="padding-top:16px;">
      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
        <v-checkbox
          v-model="hotelequia"
          label="Hotelequia"
          @change="handleChange"
          hide-details
        />
        </v-col>
      </v-row>
      <v-row v-if="hotelequia">
        <v-col
          cols="12"
          sm="12"
        >
          <v-text-field
            outlined
            v-model="hotelequiaURL"
            label="URL"
            hide-details
            @change="handleChange"
          />
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import api from '@/services/api'
export default {
  name: 'HotelequiaForm',
  props: {
    workspaceID: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    onChangeData: {
      type: Function,
      default: null,
    },
  },
  data: () => ({
    loading: true,
    hotelequia: false,
    hotelequiaURL: null,
  }),
  methods: {
    getData () {
      api.getItem('dtouch', `v1/system/workspaces/`, `${this.workspaceID}/hotelequia`)
        .then(response => {
          this.hotelequia = response && response.Hotelequia ? true : false
          this.hotelequiaURL = response ? response.HotelequiaURL : null
        })
    },
    handleExpand () {
      if(!this.hotelequia && this.workspaceID) this.getData()
    },
    handleChange () {
      this.onChangeData({
        Hotelequia: this.hotelequia,
        HotelequiaURL: this.hotelequiaURL,
      })
    }
  },
}
</script>


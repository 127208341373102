<template>
  <v-expansion-panel 
    @click="handleExpand"
  >
    <v-expansion-panel-header>{{ $t('dtouch.workspace.screensaver', locale) }}</v-expansion-panel-header>
    <v-expansion-panel-content style="padding-top:16px;">
      <v-row v-if="screensaverData">
        <v-col
          cols="12"
          sm="12"
          style="padding:20px"
        >
          <small>
            Dviewcontent XML URL :
          </small>
          <v-text-field
            hide-details
            outlined
            v-model="screensaverXMLURL"
            style="margin-top: 10px;"
            @change="handleChangeData"
          />
          <small>
            <i>
              &screensaver=true&screensaverTime=300000
            </i>
          </small>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import api from '@/services/api'
export default {
  name: 'ScreensaverForm',
  props: {
    workspaceID: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    onChangeData: {
      type: Function,
      default: null,
    },
  },
  data: () => ({
    languages: [ 'es', 'en', 'de' ],
    screensaverData: null,
    loading: true,
    screensaverXMLURL: null,
  }),
  methods: {
    getWorkspaces () {
      api.getAllWithoutLimit('dtouch', 'v1/system/workspaces')
        .then(response => {
          this.dtouchWorkspaces = response.data.map(item => {
            return ({
              id: item.ID,
              name: item.Name,
              domain: item.Domain,
            })
          })
          this.loading = false
          if(!this.screensaverData && this.workspaceID) this.getData()
      })
    },
    getData () {
      api.getItem('dtouch', `v1/public/workspaces/`, `${this.workspaceID}/screensaver`)
        .then(response => {
          this.screensaverXMLURL = response.Screensaver
          this.screensaverData = { url: this.screensaverXMLURL }
          this.handleChangeData()
        })
    },
    handleExpand () {
      this.getWorkspaces()
    },
    handleChangeData () {
      this.onChangeData({
        screensaverData: {
          url: this.screensaverXMLURL,
        }
      })
    }
  },
}
</script>


<template>
  <v-expansion-panel 
    @click="handleExpand"
  >
    <v-expansion-panel-header>{{ $t('dtouch.workspace.dfile.title', locale) }}</v-expansion-panel-header>
    <v-expansion-panel-content style="padding-top:16px;">
      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
          <v-autocomplete
            v-model="dfileWorkspace"
            :items="zones"
            :loading="loading"
            multiple
            outlined
            dense
            item-text="name"
            item-value="id"
            :label="$t('dtouch.workspace.dfile.id', locale)"
            hide-details
            @change="handleChangeData"
          />
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import api from '@/services/api'
export default {
  name: 'DfileForm',
  props: {
    workspaceID: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    onChangeData: {
      type: Function,
      default: null,
    },
  },
  data: () => ({
    dfileWorkspace: null,
    zones: [],
    loading: true
  }),
  methods: {
    getWorkspaces () {
      api.getAllWithoutLimit('dfiles', 'v1/internal/workspaces')
        .then(response => {          
          this.zones = response.map(item => {
            return ({
              id: item.ID,
              name: item.Name
            })
          })
          this.loading = false
      })
    },
    getDfileData () {
      api.getItem('dtouch', `v1/system/workspaces/`, `${this.workspaceID}/dfile`)
        .then(response => {
          if (response) this.dfileWorkspace = response.dfileworkspace.split(',')
        })
    },
    handleExpand () {
      this.getWorkspaces()
      if(!this.dfileWorkspace && this.workspaceID) this.getDfileData()
    },
    handleChangeData () {
      this.onChangeData({
        dfileWorkspace: this.dfileWorkspace.join(',')
      })
    }
  },
}
</script>


<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      style="overflow: none;"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          block
        >
          Vista previa
        </v-btn>
      </template>
      <v-card>
        <v-toolbar
          dark
          color="primary"
          elevation="0"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Vista previa</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="dialog = false"
            >
              {{ $t('common.close', locale)}}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <main style="background-color:white;min-height: calc(100vh);">
          <v-row no-gutters>
            <v-col
              cols="6"
              xs="6"
              style="padding-left: 70px;padding-top: 70px;text-aling: center"
              v-if="size && url"
            >
              <v-card 
                elevation="3"
                style="width:454px; height:806px;"
              >
                  <iframe 
                    :key="url"
                    :src="url"
                    id="frame"
                    frameborder="0"
                  />
              </v-card>
            </v-col>
            <v-col
              cols="6"
              xs="6"
              style="padding: 70px"
            >
              <center>
                <v-card
                  color="transparent"
                  style="max-width: 800px;padding: 10px 20px 15px 20px;"
                  elevation="3"
                >
                  <v-checkbox
                    v-model="fullscreen"
                    label="PC"
                    @change="handlePrepareURL"
                    hideDetails
                  />
                  <v-checkbox
                    v-model="isInverted"
                    label="Invertir parrilla"
                    @change="handlePrepareURL"
                  />
                  <v-menu location="bottom">
                    <template v-slot:activator="{ props }">
                      <v-btn
                        color="primary"
                        dark
                        v-bind="props"
                      >
                        Tiempo protector de pantalla [{{ screensaverTime}}]
                      </v-btn>
                    </template>

                    <v-list>
                      <v-list-item
                        v-for="(item, index) in times"
                        :key="index"
                        @click="screensaverTime = item.time"
                      >
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-card>
              </center>
            </v-col>
          </v-row>
        </main>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  props: {
    domain: {
      type: String,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    url: null,
    fullscreen: true,
    isInverted: false,
    size: null,
    screensaverTime: 300000,
  }),
  computed: {
    times () {
      return [
        {
          name: '5min',
          time: 300000,
        },
        {
          name: '5seg',
          time: 5000,
        },
      ]
    },
  },  
  watch: {
    screensaverTime () {
      this.handlePrepareURL()
    },
    dialog () {
      this.handlePrepareURL()
    },
  },
  mounted () {
    this.handlePrepareURL()
  },
  methods: {
    getParameterByName (name) {
      const match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search)
      return match && decodeURIComponent(match[1].replace(/\+/g, ' '))
    },
    async handleGetSize () {
      return this.scaleSize({
        width: 1080,
        height: 1920,
      })
    },
    async handlePrepareURL () {
      if (!this.dialog) return
      const baseURL = process.env.VUE_APP_ENV === 'development' ? 
        `http://${this.domain}.dtouch.local:${window.location.port === '8080' ? '8081' : '8080'}/#/totem?`
        : `https://${this.domain}.dtouch.es/#/totem?`
      this.url = `${baseURL}fullscreen=${this.fullscreen}&isInverted=${this.isInverted}&screensaverTime=${this.screensaverTime}`
      this.size = await this.handleGetSize()
    },
    scaleSize (v) {
      const maxHeight = 800
      if (v.height > maxHeight) {
        v.width = v.width * maxHeight / v.height
        v.height = maxHeight
      }
      return v
    },
  },
}
</script>

<style>
* {
  font-family: 'Roboto';
}
#frame {
  width: 1080px;
  height: 1920px;
  background-color: transparent;
  -ms-zoom: 0.42;
  -moz-transform: scale(0.42);
  -moz-transform-origin: 0 0;
  -o-transform: scale(0.42);
  -o-transform-origin: 0 0;
  -webkit-transform: scale(0.42);
  -webkit-transform-origin: 0 0;
  border-radius: 10px;
}
</style>


<template>
  <v-layout wrap v-if="users"
    class="select-sys-users-permissions"
  >
    <v-flex xs4 style="padding: 10px;">
      {{ $t('workspace.users', locale) }}
    </v-flex>
    <v-flex xs8 class="d-flex justify-end" style="padding: 0 5px 10px 0;">
      <search :onSearch="handleSearch" />
    </v-flex>
    <v-flex xs12>
      <v-expansion-panels popout>
        <v-expansion-panel
          v-for="(user,i) of users"
          :key="i"
        >
          <v-expansion-panel-header>
            <v-row
              justify="space-around"
              no-gutters
            >
              <v-col cols="11" style="padding-top: 4px;">
                {{ user.Name }}
              </v-col>
              <v-col cols="1">
                <v-icon v-if="!user.Active" color="green" @click.stop.prevent="handleAdd(user)">mdi-plus-circle</v-icon>
                <v-icon v-if="user.Active" color="red" @click.stop.prevent="handleRemove(user)">mdi-delete-circle</v-icon>
              </v-col>
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-divider />
            <v-row
              v-if="area === 'turismo' && userPermission && userPermission.length > 0"
            >
              <v-col cols="12" class="label">
                {{ $t('workspace.sidebarPermissions', locale) }}
              </v-col>
              <v-col cols="12"
                v-for="(permission,i) of userPermission"
                :key="`permission${i}`"
              >
                <v-checkbox
                  v-model="user.Permissions"
                  :label="$t(`turismo.permission.${permission.name}`, locale)"
                  :value="permission.id"
                  @change="handleChangePermissions"
                  hide-details
                  style="margin: 5px 0 !important;"
                />
                <small>
                  {{ $t(`turismo.permission.${permission.name.concat('Info')}`, locale) }}
                </small>
              </v-col>
            </v-row>
            <v-row
              v-else-if="area === 'players' && userPermission && userPermission.length > 0"
            >
              <v-col cols="12" class="label">
                {{ $t('workspace.sidebarPermissions', locale) }}
              </v-col>
              <v-col cols="12"
                v-for="(permission,i) of userPermission"
                :key="`permission${i}`"
              >
                <v-checkbox
                  v-model="user.Permissions"
                  :label="$t(`players.permission.${permission.name}`, locale)"
                  :value="permission.id"
                  @change="handleChangePermissions"
                  hide-details
                  style="margin: 5px 0 !important;"
                />
                <small>
                  {{ $t(`players.permission.${permission.name.concat('Info')}`, locale) }}
                </small>
              </v-col>
            </v-row>
            <v-row
              v-else-if="userPermission && userPermission.length > 0"
            >
              <v-col cols="12" class="label">
                {{ $t('workspace.sidebarPermissions', locale) }}
              </v-col>
              <v-col cols="12"
                v-for="(permission,i) of userPermission"
                :key="`permission${i}`"
              >
                <v-checkbox
                  v-model="user.Permissions"
                  :label="permission.name"
                  :value="permission.id"
                  @change="handleChangePermissions"
                  hide-details
                  style="margin: 5px 0 !important;"
                />
              </v-col>
            </v-row>
            <v-row
              v-if="area === 'dfiles'"
            >
              <v-col cols="12">
                <v-divider />
                <v-switch
                  v-model="user.Readonly"
                  :label="$t('workspace.readonly')"
                  @change="handleChangePermissions"
                />
              </v-col>
            </v-row>
            <v-row
              v-if="area === 'dtouch'"
            >
              <v-col cols="12">
                <v-switch 
                  v-if="user.Permissions.indexOf('4a9cb44f-1063-4e64-b336-ee44880a58d5') >= 0"
                  v-model="user.HideDishOrderPoint"
                  :label="$t('workspace.hideDishOrderPoint')"
                  @change="handleChangePermissions"
                />
                <v-divider />
                <v-switch
                  v-model="user.HideByType"
                  :label="$t('workspace.hideItemByType')"
                  @change="handleChangePermissions"
                />
                <v-divider />
                <v-switch
                  v-model="user.CloneMenu"
                  :label="$t('workspace.cloneMenu')"
                  @change="handleChangePermissions"
                />
                <v-divider />
                <v-switch
                  v-model="user.CloneContent"
                  :label="$t('workspace.cloneContent')"
                  @change="handleChangePermissions"
                />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-flex>
  </v-layout>
</template>
<script>
  import { mapState } from 'vuex'
  import api from '@/services/api'
  import dtouchConstants from '@/constants/dtouch'
  import bookingConstants from '@/constants/booking'
  import Search from '@/components/Search'
  export default {
    components: {
      Search,
    },
    props: {
      area: {
        type: String,
        required: false,
        default: null
      },
      workspaceID: {
        type: String,
        required: false,
        default: null
      },
      onChangeUser: {
        type: Function,
        required: false,
        default: null
      }
    },
    data () {
      return {
        loading: false,
        users: null,
        // [{
        //    ID: 'XXXX',
        //    Name: 'User',
        //    Avatar: 'https:xxx.png',
        //    Permissions: null,
        // }]
        search: '',
        activeUsers: [],
        // [{
        //    WorkspaceID: 'XXXX',
        //    UserID: 'XXXX',
        //    Permissions: [ 1, 2 ]
        // }]
        userPermission: null,
      }
    },
    computed: {
      ...mapState('app',['locale']),
    },
    watch: {
      workspaceID (newItem, oldItem) {
        if (newItem !== oldItem) this.handleGetActiveUser ()
      },
    },
    mounted () {
      this.handleGetUserPermission()
      this.handleGetActiveUser()
    },
    methods: {
      handleGetUserPermission () {
        if (this.area === 'booking') this.userPermission = bookingConstants.userPermission
        else if (this.area === 'turismo')
          api.getAllWithoutLimit ('turismo', 'v1/internal/constants/user-permissions')
            .then(response => {
              this.userPermission = response.userPermission
            })
        else if (this.area === 'dfiles') this.userPermission = []
        else if (this.area === 'players')
          api.getAllWithoutLimit ('players', 'v1/internal/constants/user-permissions')
            .then(response => {
              this.userPermission = response
            })
        else this.userPermission = dtouchConstants.userPermission
      },
      handleSearch (v) {
        this.search = v
        this.handleGetUserFiltered()
      },
      handleGetActiveUser () {
        this.loading = true
        this.activeUsers = []
        // get the users connected to the workspaces
        if (this.workspaceID) api.getAll (this.area, `v1/system/workspaces/${this.workspaceID}/users`, 0, 10000, '')
          .then(response => {
            // [{
            //    WorkspaceID: 'XXXX',
            //    UserID: 'XXXX',
            //    Permissions: [ 1, 2 ]
            // }]
            this.activeUsers = response.map(item => {
              item.Permissions = item.Permissions ? JSON.parse(item.Permissions) : null
              if (this.area === 'dtouch') {
                item.HideDishOrderPoint = item.HideDishOrderPoint === 1
                item.HideByType = item.HideByType === 1
                item.CloneMenu = item.CloneMenu === 1
                item.CloneContent = item.CloneContent === 1
              } else if (this.area === 'dfiles') item.Readonly = item.Readonly === 1
              return item
            })
            this.handleGetUserFiltered()
          })
        else this.handleGetUserFiltered()
      },
      handleGetUserFiltered () {
        if (this.activeUsers || (this.search && this.search.length > 0)) {
          const filters = {
            activeUsersID: this.activeUsers.map((item) => { return item.UserID }),
            search: this.search,
            area: this.area,
            workspaceID: this.workspaceID
          }
          api.getActiveData ('system', `v1/system/users/active-and-filtered`, filters)
            .then(response => {
              this.users = response.data.map(item => {
                const aux = this.activeUsers.filter(x => x.UserID === item.ID)
                item.Permissions= aux.length > 0 && aux[0].Permissions ? aux[0].Permissions : []
                item.Active = aux.length > 0
                if (this.area === 'dtouch') {
                  item.HideDishOrderPoint = aux.length > 0 ? aux[0].HideDishOrderPoint : false
                  item.HideByType = aux.length > 0 ? aux[0].HideByType : false
                  item.CloneMenu = aux.length > 0 ? aux[0].CloneMenu : false
                  item.CloneContent = aux.length > 0 ? aux[0].CloneContent : false
                } else if (this.area === 'dfiles') item.Readonly = aux.length > 0 ? aux[0].Readonly : false

                return item
              })
            this.loading = false
            this.handleUpdate()
          })
        } else {
          this.users = []
          this.loading = false
          this.handleUpdate()
        }
      },
      handleAdd (v) {
        v.Active = true
        
        if (this.area === 'dtouch') {
          this.activeUsers.push({
            WorkspaceID: this.workspaceID,
            UserID: v.ID,
            Permissions: v.Permissions,
            HideDishOrderPoint: false,
            HideByType: false,
            CloneMenu: false,
            CloneContent: false,
          })
        } else if (this.area === 'dfiles') {
          this.activeUsers.push({
            WorkspaceID: this.workspaceID,
            UserID: v.ID,
            Readonly: v.Readonly,
          })
        } else {
          this.activeUsers.push({
            WorkspaceID: this.workspaceID,
            UserID: v.ID,
            Permissions: v.Permissions,
          })
        }
        this.handleUpdate()
      },
      handleRemove (v) {
        this.activeUsers = this.activeUsers.filter(x => x.UserID !== v.ID)
        v.Active = false
        this.handleUpdate()
      },
      handleChangePermissions () {
        this.handleUpdate()
      },
      handleUpdate () {
        this.onChangeUser(this.users.filter(x => x.Active).map(item => { 
          if (this.area === 'dtouch') return { ID: item.ID, Permissions: item.Permissions, HideByType: item.HideByType, CloneMenu: item.CloneMenu, CloneContent: item.CloneContent, HideDishOrderPoint: item.HideDishOrderPoint } 
          else if(this.area === 'dfiles') return { ID: item.ID, Readonly: item.Readonly } 
          else return { ID: item.ID, Permissions: item.Permissions } 
        }))
      },
    }
  }
</script>
<style scoped>
.select-sys-users-permissions {
  background-color: rgba(0, 0, 0, 0.06);
  padding: 10px;
  border-radius: 5px;
}
.col {
  padding: 0;
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
}
.col.label {
  margin-top: 20px !important;
}
</style>


<template>
  <v-expansion-panel 
    @click="handleExpand"
  >
    <v-expansion-panel-header>{{ $t('dtouch.workspace.messages.title', locale) }}</v-expansion-panel-header>
    <v-expansion-panel-content style="padding-top:16px;">
      <v-layout wrap>
        <v-flex xs12>
          <v-switch
            v-model="isMessages"
            :label="$t('dtouch.workspace.messages.active', locale)"
            @change="handleChange"
            hide-details
          />
        </v-flex>
        <v-flex xs12
          v-if="isMessages"
        >
          <v-checkbox
            v-model="authRequired"
            :label="$t('dtouch.workspace.messages.authRequired', locale)"
            @change="handleChange"
            hide-details
          />
        </v-flex>
        <v-col
          v-if="isMessages"
          cols="12"
          sm="12"
        >
          <v-text-field
            outlined
            v-model="identForm"
            :label="$t('dtouch.workspace.messages.identForm', locale)"
            hide-details
            @change="handleChange"
          />
          <small>EJ: name,room</small>
        </v-col>
      </v-layout>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import api from '@/services/api'
export default {
  name: 'MessagesForm',
  props: {
    workspaceID: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    onChangeData: {
      type: Function,
      default: null,
    },
  },
  data: () => ({
    loading: true,
    isMessages: null,
    authRequired: null,
    identForm: null
  }),
  methods: {
    getData () {
      api.getItem('dtouch', `v1/system/workspaces/`, `${this.workspaceID}/messages-config`)
        .then(response => {
          if (!response) return

          this.isMessages = response.Active ? true : false
          this.authRequired = response.AuthRequired ? true : false
          this.identForm = response.IdentForm
        })
    },
    handleExpand () {
      if(this.isMessages === null && this.workspaceID) this.getData()
    },
    handleChange () {
      this.onChangeData({
        IsMessages: this.isMessages,
        AuthRequired: this.authRequired,
        IdentForm: this.identForm,
      })
    },
  },
}
</script>


<template>
  <v-expansion-panel 
    @click="handleExpand"
  >
    <v-expansion-panel-header>{{ $t('dtouch.issues.title', locale) }}</v-expansion-panel-header>
    <v-expansion-panel-content style="padding-top:16px;">
      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
          <v-text-field
            v-model="mails"
            :label="$t('dtouch.request.email', locale)"
            outlined
            hide-details
            @change="handleChange"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          style="padding: 0 20px 10px 20px;"
        >
          <translate
            v-if="btnText"
            v-model="btnText"
            :languages="languages"
            label="Texto bot�n"
            type="text"
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          style="padding: 10px;"
        >
          <categories-list 
            :locale="locale"
            :categories="categories"
            :onChange="handleChangeCategories"
          />
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import api from '@/services/api'
import Translate from '@/components/Translate'
import CategoriesList from './CategoriesList'
export default {
  name: 'RequestsForm',
  components: {
    Translate,
    CategoriesList,
  },
  props: {
    workspaceID: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    onChangeData: {
      type: Function,
      default: null,
    },
  },
  data: () => ({
    mails: null,
    workspaces: [],
    loading: true,
    btnText: null,
    categories: null,
  }),
  computed: {
    languages () {
      return ['es', 'en', 'fr', 'de', 'it', 'nl', 'ca']
    },
  },
  watch: {
    btnText () {
      this.handleChange()
    },
  },
  methods: {
    getData () {
      api.getItem('dtouch', `v1/system/workspaces/`, `${this.workspaceID}/issues-config`)
        .then(response => {
          this.mails = response ? response.NotificationMails : null
          this.btnText = response && response.BtnText ? JSON.parse(response.BtnText) : { es: 'Reportar', en: 'Report'}
          this.categories = response && response.Categories ? JSON.parse(response.Categories) : []
        })
    },
    handleExpand () {
      if(!this.mails && this.workspaceID) this.getData()
    },
    handleChangeCategories (v) {
      this.categories = v
      this.handleChange()
    },
    handleChange () {
      this.onChangeData({
        NotificationMails: this.mails,
        BtnText: this.btnText,
        Categories: this.categories,
      })
    }
  },
}
</script>


<template>
  <v-expansion-panel 
    @click="handleExpand"
  >
    <v-expansion-panel-header>{{ $t('dtouch.workspace.contentmanagerBook.title', locale) }}</v-expansion-panel-header>
    <v-expansion-panel-content style="padding-top:16px;">
      <v-row>
        <v-col
          cols="12"
          sm="12"
        >
        <!--v-select
          v-model="selectedBookID"
          :items="bookingWorkspaces"
          :label="$t('dtouch.workspace.contentmanagerBook.bookingWorkspace', locale)"
          item-text="Name"
          item-value="ID"
          outlined
          hide-details
        /-->
        <v-autocomplete
          v-model="selectedBookID"
          :items="bookingWorkspaces"
          outlined
          chips
          small-chips
          item-text="Name"
          item-value="ID"
          :label="$t('dtouch.workspace.contentmanagerBook.bookingWorkspace', locale)"
          multiple
          hide-details
        />
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>
<script>
import api from '@/services/api'
export default {
  name: 'AuthenticationForm',
  props: {
    workspaceID: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    onChangeData: {
      type: Function,
      default: null,
    },
  },
  data: () => ({
    selectedBookID: null,
    bookingWorkspaces: [],
  }),
  watch: {
    selectedBookID () {
      this.handleChangeData()
    },
  },
  methods: {
    getBookData () {
      api.getItem('dtouch', `v1/system/workspaces/`, `${this.workspaceID}/contentmanager-book`)
        .then(response => {
          if (response) this.selectedBookID = response.CMBookID.split(',')
        })
    },
    getBookWorkspaces () {
      api.getAll('booking', 'v1/system/workspaces', 0, 1000)
        .then(response => {
          this.bookingWorkspaces = response.data
        })
    },
    handleExpand () {
      if(!this.selectedBookID) {
        this.getBookWorkspaces()
        if (this.workspaceID) this.getBookData()
      }
    },
    handleChangeData () {
      this.onChangeData({
        cMBookID: this.selectedBookID.join(',')
      })
    }
  },
}
</script>

